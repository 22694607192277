.agreement p {
  font-size: unset;
}

.agreement .agreement-title {
  font-size: 2em;
  margin: 0.4em 0;
}

.agreement div.seperator {
  width: 20em;
  background-color: var(--color-primary);
  ;
  height: 0.17em;
  margin-left: -1.8em;
  margin-bottom: 3em;
}

@media(max-width:950px) {

  .agreement div.seperator {
    margin-left: -2px;
    width: 18em;
  }

  .agreement {
    justify-content: center;
    margin-left: unset;
  }

}