.ordered-list {
  list-style: decimal;

}

.ordered-list li {
  margin: 1rem 0;
}

.letters-list {
  list-style: lower-alpha;
  margin: 0.5rem 0;
}

.letters-list li {
  margin: 0.7rem 0;
}

.roman-list {
  list-style: lower-roman;
}

.roman-list li {
  margin: 0.7rem 0;
}

.privacy p {
  font-size: unset;
}

.privacy .privacy-title {
  font-size: 2em;
  margin: 0.4em 0;
}

.privacy div.seperator {
  width: 15em;
  background-color: var(--color-primary);
  ;
  height: 0.17em;
  margin-left: -1.8em;
  margin-bottom: 3em;
}

@media(max-width:950px) {

  .privacy div.seperator {
    margin-left: -2px;
    width: 14em;
  }

  .privacy {
    justify-content: center;
    margin-left: unset;
  }

}

#privacy a:hover {
  color: rgb(0, 0, 197) !important;
  text-decoration: underline;
}