 .disclaimer {
   text-align: center;
 }

 u {
   font-weight: 500;
 }

 .disclaimer p {
   margin: 0px;
   font-size: 14px;
   /* line-height:0.6; */

   font-weight: 400;
 }

 u {
   font-weight: 500;
 }

 @media screen and (min-width: 0px) and (max-width: 480px) {
   .disclaimer p {
     font-size: 10px;
   }
 }

 /*--------------------------------------------------------------
# affiliates Section
--------------------------------------------------------------*/
 .affiliates h2 {
   font-size: 48px;
   font-weight: 700;
   /* font-family: var(--font-secondary); */
   margin: 30px 0;
 }

 @media (min-width: 991px) {
   .affiliates h2 {
     max-width: 65%;
     margin: 0 0 80px 0;
   }
 }

 .affiliates .our-story {
   padding: 40px;
   background-color: #f5f6f7;
 }

 @media (min-width: 991px) {
   .affiliates .our-story {
     padding-right: 35%;
   }
 }

 .affiliates .our-story h4 {
   text-transform: uppercase;
   font-size: 18px;
   color: #838893;
 }

 .affiliates .our-story h3 {
   font-size: 36px;
   font-weight: 700;
   margin-bottom: 20px;
   color: var(--color-secondary);
 }

 .affiliates .our-story p:last-child {
   margin-bottom: 0;
 }

 .affiliates ul {
   list-style: none;
   padding: 0;
   font-size: 15px;
 }

 .affiliates ul li {
   padding: 5px 0;
   display: flex;
   align-items: center;
 }

 .affiliates ul i {
   font-size: 20px;
   margin-right: 4px;
   color: var(--color-primary);
 }

 .affiliates .watch-video i {
   font-size: 32px;
   transition: 0.3s;
   color: var(--color-primary);
 }

 .affiliates .watch-video a {
   font-weight: 600;
   color: var(--color-secondary);
   margin-left: 8px;
   transition: 0.3s;
 }

 .affiliates .watch-video:hover a {
   color: var(--color-primary);
 }

 .affiliates .affiliates-img {
   min-height: 600px;
   background-size: cover;
   background-position: center;
   background-image: url(./affiliates.jpg);
 }



 @media (min-width: 992px) {
   .affiliates .affiliates-img {
     position: absolute;
     top: 0;
     right: 0;
   }

 }

 @media (max-width: 991px) {
   .affiliates h2 {
     font-size: 32px;
   }

   .affiliates h3 {
     font-size: 30px;
   }
 }


 /*modal*/
 .modal.show {
   padding-left: 0px !important;
 }

 .modal.show .modal-dialog {
   max-width: 1000px;
 }

 .affiliates.php-email-form {
   width: 100%;
   /* border-top: 3px solid var(--color-primary);;
    border-bottom: 3px solid var(--color-primary);; */
   padding: 15px;
   /* background: var(--bg-color-secondary); */
   /* background: #FAF5F0; */
   /* box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12); */
 }

 .affiliates.php-email-form .form-group {
   padding-bottom: 8px;
 }

 .affiliates.php-email-form .validate {
   display: none;
   color: red;
   margin: 0 0 15px 0;
   font-weight: 400;
   font-size: 13px;
 }

 .affiliates.php-email-form .error-message {
   display: none;
   color: #fff;
   background: #ed3c0d;
   text-align: left;
   padding: 15px;
   font-weight: 600;
 }

 .affiliates.php-email-form .error-message br+br {
   margin-top: 25px;
 }

 .affiliates.php-email-form .sent-message {
   display: none;
   color: #fff;
   background: #18d26e;
   text-align: center;
   padding: 15px;
   font-weight: 600;
 }

 .affiliates.php-email-form .loading {
   display: none;
   background: #fff;
   text-align: center;
   padding: 15px;
 }

 .affiliates.php-email-form .loading:before {
   content: "";
   display: inline-block;
   border-radius: 50%;
   width: 24px;
   height: 24px;
   margin: 0 10px -6px 0;
   border: 3px solid #18d26e;
   border-top-color: #eee;
   animation: animate-loading 1s linear infinite;
 }

 .affiliates.php-email-form .form-group {
   margin-bottom: 20px;
 }

 .affiliates.php-email-form label {
   padding-bottom: 8px;
 }

 .affiliates.php-email-form input,
 .affiliates.php-email-form textarea {
   border-radius: 0;
   box-shadow: none;
   font-size: 14px;
   border-radius: 4px;
   background: var(--bg-color-secondary);
 }

 .affiliates.php-email-form input:focus,
 .affiliates.php-email-form textarea:focus {
   border-color: var(--color-primary);
   ;
 }

 .affiliates.php-email-form input {
   height: 44px;
 }

 .affiliates.php-email-form textarea {
   padding: 10px 12px;
 }

 /* .affiliates.php-email-form button[type=submit] {
    background: var(--color-primary);;
    border: 0;
    padding: 12px 34px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  } */

 .affiliates.php-email-form button[type=submit]:hover {
   background: var(--color-primary-hover);
   ;
 }

 .modal-header .btn-close:hover {
   background-color: #5a4c3c3d;
 }

 @keyframes animate-loading {
   0% {
     transform: rotate(0deg);
   }

   100% {
     transform: rotate(360deg);
   }
 }

 @media(max-width:500px) {
   .php-email-form.affiliates {
     padding-right: 10px;
     padding-left: 10px;
   }
 }