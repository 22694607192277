#home {
  overflow-x: hidden;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.hero h1 {
  font-size: 48px;
  letter-spacing: 0;
  font-weight: 700;
}

.hero p {
  font-size: 26px;
  letter-spacing: 0;
  font-weight: 500;
}

.hero img {
  border-radius: 30px;
  /* width: 35vw; */
  width: 100%;
}

@media (max-width:991px) {
  .hero {
    flex-direction: column;
    gap: 25px;
  }

  @media (max-width:768px) {
    .hero h1 {
      font-size: 35px;
    }

    .hero p {
      font-size: 20px;
    }
  }

  /*
  .hero div {
    width: 90vw;
  } */
}

/*--------------------------------------------------------------
# about
--------------------------------------------------------------*/
.about {
  display: flex;
  gap: 20px;
}

.about img {
  /* width: 30vw;
  height: 100%; */
  width: 100%;
  border-radius: 30px;
  object-fit: cover;
  /* border-radius: 10px; */
  aspect-ratio: 17 / 19;
}

.about h1 {
  font-size: 32px;
  font-weight: bold;
}

.about-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width:991px) {
  .about {
    flex-direction: column-reverse;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid var(--color-primary);
  ;
  border-bottom: 3px solid var(--color-primary);
  ;
  padding: 30px;
  background: var(--bg-color-secondary);
  /* background:#FAF5F0; */
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  height: fit-content;
  padding-bottom: 8px;
}

.contact .info i {
  font-size: 20px;
  color: var(--color-primary);
  ;
  float: left;
  width: 44px;
  height: 44px;
  background: #F2EEE9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--font-heading-color);
  ;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--font-color);
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: var(--color-primary);
  ;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: var(--color-primary);
  ;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  border-top: 3px solid var(--color-primary);
  ;
  border-bottom: 3px solid var(--color-primary);
  ;
  padding: 30px;
  background: var(--bg-color-secondary);
  /* background: #FAF5F0; */
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}


.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
  background: var(--bg-color-secondary);
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-primary);
  ;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

/* .contact .php-email-form button[type=submit] {
    background: var(--color-primary);;
    border: 0;
    padding: 12px 34px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  } */

.contact .php-email-form button[type=submit]:hover {
  background: var(--color-primary-hover);
  ;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media(max-width:500px) {
  .contact .php-email-form {
    padding: 30px 20px;
  }

  .contact .info {
    padding: 30px 20px;
    padding-bottom: 8px;
  }
}


/*--------------------------------------------------------------
# tools
--------------------------------------------------------------*/
.tools {
  padding: 15px 0;
  /* margin: 45px 0; */
  text-align: center;
  margin-top: 40px;
}

.tools .item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.tools img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px auto;
  filter: grayscale(100);
}

.tools img:hover {
  filter: none;
  transform: scale(1.15);
}

.tools .owl-carousel {
  z-index: unset;
}

.section-bg {
  background-color: #f1f1f1;
}
.tools .tools-p{
  font-style: italic;
  font-weight: 500;
}

@media (max-width: 768px) {
  .tools {
    margin-top: 0px;
  }

  .tools img {
    max-width: 40%;
  }

  .tools .row {
    row-gap: 10px;
  }

  .tools .row div {
    row-gap: 10px;
    background-color: #f1f1f1;
    height: 70px;
  }

  .section-bg {
    /* background-color: transparent; */
    padding: 0px;
  }
  .tools .tools-p{
    font-size: 13px;
  }
}

@media (max-width: 500px) {
  .tools img {
    max-width: 60%;
  }
  .tools .tools-p{
    font-size: 11px;
  }
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/

.industries .card {
  border: 1px solid rgba(14, 29, 52, 0.15);
  background: #fff;
  position: relative;
  border-radius: 24px;
  height: 100%;
  background: var(--bg-color-secondary);
}

.industries .card .card-img {
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 24px 24px 0px 0px;
  aspect-ratio: 3/2;
}

.industries .card .card-img img {
  transition: 0.3s ease-in-out;
}

.industries .card h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
  padding: 10px 30px;
  text-transform: uppercase;
}

.industries .card a {
  color: var(--font-heading-color);
  ;
  ;
  transition: 0.3;
}

.industries .card a:hover {
  color: var(--color-primary);
}

.industries .card p {
  padding: 0 30px;
  margin-bottom: 30px;
  color: var(--color-secondary);
  font-size: 15px;
}

.industries .card:hover .card-img img {
  transform: scale(1.1);
}



/*--------------------------------------------------------------
# pricing
--------------------------------------------------------------*/

.pricing-cards {
  /* display: flex;*/
  justify-content: center;
  gap: 24px;

}

.pricing-card {
  background-color: rgb(255, 255, 255);
  background-color: var(--bg-color-secondary);
  border-radius: 24px;
  box-shadow: rgba(0, 44, 155, 0.15) 0px 6px 20px -6px;
  /* display: flex;
  flex-direction: column; */
  padding: 32px 24px;
  position: relative;
  transition: box-shadow 0.4s ease 0s, transform 0.4s ease 0s;
  /* width: 20vw;  */
}

.homepage .pricing-card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 44, 155, 0.15) 0px 10px 20px -2px;

  transform: translateY(-4px) !important;
  transition-delay: unset !important;
  transition-timing-function: unset !important;
  transition-duration: unset !important;
  transition: box-shadow 0.4s ease 0s, transform 0.4s ease 0s !important;

}

.pricing-card .card-title {
  font-weight: 600;
  line-height: 1.33333;
  color: var(--font-heading-color);
  ;
  ;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 120%;
  margin: 0px;
  text-align: center;
}

.card-divider {
  background-image: url(./divider.svg);
  height: 1px;
  margin: 32px 0px;
  margin: 20px 0px;
  width: 100%;
}

.card-info-heading {
  position: relative;
  margin-bottom: 5px;
  text-align: center;
}

.card-info-heading div {
  margin-left: 8px;
}

.card-info-heading h4 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--font-heading-color);
  ;
  ;
  /* font-size: 24px;
  font-weight: 500; */
  letter-spacing: -0.5px;
  line-height: 120%;
  margin: 0px;
}

.card-info-heading .h4 {
  text-align: left;
}

.card-info-heading .week {
  color: red;
  text-decoration: none;
  color: #949ca1 !important;
  font-weight: 500;
  font-size: 1.0625rem;
  line-height: 1.44;
  font-style: italic;
  margin-left: 5px;
  white-space: wrap;
}

.card-info-heading span {
  font-size: 24px;
  white-space: normal;
}

.month-text {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
}

.card-info ul {
  color: rgb(77, 91, 124);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 10px 0px;
  padding: 0;
}

.card-info li {
  color: rgb(77, 91, 124);
  display: flex;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}
.pricing-cards button{
  width: fit-content;
  margin-top: -8px;
  background: transparent;
  color: var(--font-color);
  font-weight: 400;
  border: 1px solid var(--color-primary);
  transition: 0.4s ease;
  padding: 12px 45px;
}
.pricing-cards button:hover{
  color: white;
  background-color: var(--color-primary); 
}

@media (min-width:768px) {
  .pricing-card {
    width: 48%;

  }

  .pricing-cards {
    justify-content: center;
    gap: 0px;
    column-gap: 24px;
  }
}

@media(max-width:768px) {
  .pricing-cards {
    row-gap: 0px;

  }

  .pricing {
    padding: 20px 12px;
  }
}

@media (min-width:992px) {
  .pricing-card {
    width: 31%;
  }

  .pricing-cards {
    gap: 30px;
  }
}

@media(max-width:500px) {
  .pricing-card {
    padding: 32px 20px;
  }
}

.homepage .disclaimer p {
  font-size: 14px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.services .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: var(--color-primary);
  overflow: hidden;
  /* box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15); */
  box-shadow: 0px 0 25px rgba(189, 165, 59, 0.171);
  /* box-shadow: 0px 0 25px rgba(0, 44, 155, 0.15); */
  /* box-shadow: 0px 0 25px #bdbab6; */

}

.services .icon i {
  font-size: 36px;
  line-height: 0;
}

.services .icon img {
  width: 40px;
  line-height: 0;
}

.services:hover .icon {
  /* box-shadow: 0px 0 25px rgba(92, 159, 36, 0.3);
  box-shadow: 0px 0 25px rgba(0, 44, 155, 0.15); */
  box-shadow: 0px 0 25px rgba(189, 165, 59, 0.171);
}

.services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.services .title a {
  color: #444444;
  transition: 0.3s;
}

.services .title a:hover {
  color: var(--color-primary);
}

.services .title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  bottom: 0;
  left: calc(50% - 25px);
}

.services .description {
  line-height: 24px;
  font-size: 14px;
  font-size: 15px;
}




/* .swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #ffffff00;
  
  /* Center slide text vertically */
/*
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
} */