:root {
  --bg-color: #EDE9E4;
  --color-default: #364d59;
  --color-primary: #73614C;
  /* --color-primary:#7E99A6; */
  --color-secondary: #52565e;
  --color-primary-hover: #5a4c3c;
  --font-color: #424242;
  --font-heading-color: #292922;
  --bg-color-secondary: #ebe8e5;
  --bg-color-secondary: #e9e8e7;
  --bg-color-secondary: #fff;

}

::selection {
  background: #a1a1a1;
}

@media (max-width:768px) {
  :root {
    --bg-color-secondary: #ffffff65;
    --bg-color-secondary: #ffffffc2;
  }
}

/* :root {
    --font-default: 'Roboto'; 
    --font-primary: "Roboto";
    --font-secondary: "Roboto";
  } */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: 'Roboto'; */
  /*font-weight: 500; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);

  background-image: url('./images/topography.svg');

}


@media (max-width:1440px) {
  body {
    background-image: unset;
  }
}

.wrap {
  background: var(--bg-color);
  box-shadow: 0 2px 15px rgba(0, 0, 0, .1);
  margin: auto;
  max-width: 1440px;
}

button {
  background: var(--color-primary);
  ;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

button:hover {
  background-color: var(--color-primary-hover);
}

p {
  font-size: 20px;

}

a {
  color: unset;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.first-section {
  padding-top: 240px;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: var(--font-heading-color);
  ;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--color-primary);
  ;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
  font-weight: 600;
}

@media (max-width:991px) {
  section {
    padding: 20px 0;
  }
}

@media (max-width:768px) {
  p {
    font-size: 16px;
  }
}


/*scroll bar */
@media (min-width:768px) {
  /* ::-webkit-scrollbar {
  width: 13px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1;
  background: #d4d4d4e5;
}


::-webkit-scrollbar-thumb {
  background: #888;
  background: var(--color-primary);


}


::-webkit-scrollbar-thumb:hover {
  background: #555;
  background: var(--color-primary-hover);
} */

  ::-webkit-scrollbar {
    width: 12px;
  }


  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }


  ::-webkit-scrollbar-thumb {
    background: #888;
  }


  ::-webkit-scrollbar-thumb:hover {
    background: #555;


  }
}

@media (max-width:1279px) {
  .first-section {
    padding-top: 130px;
  }
}

.Toastify__close-button {
  padding: 2px 7px !important;
}

.Toastify__close-button:hover {
  background-color: #5a4c3c3d;
  padding: 2px 7px;
}

.Toastify__progress-bar--error {
  background-color: #F0390A !important;
}

.Toastify__toast--error .Toastify__toast-icon svg {
  fill: #F0390A;
}

.Toastify__progress-bar--success {
  background-color: #689B41 !important;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #689B41;
}