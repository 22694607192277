.faq {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 100px;
}

.faq div+div {
  clear: both;
}

.faq p {
  line-height: 1.4em;
  color: #9e9e9e;
  color: #474646;
  ;
}

.faq .faq-title {
  font-size: 2em;
  margin: 0.4em 0;
}

.faq div.seperator {
  width: 7.5em;
  background-color: var(--color-primary);
  ;
  height: 0.17em;
  margin-left: -1.8em;
}

.faq .faq-list>div {
  border-bottom: 0.07em solid #ededed;
  padding: 1.5em 0em;
  border-bottom: 0.07em solid #929292;
  border-bottom: 0.07em solid #b3b3b3;
}

.faq .faq-list>div:last-child {
  border: unset;
}

.faq details>summary {
  list-style: none;
}

.faq details>summary::-webkit-details-marker {
  display: none;
}

.faq summary {
  font-size: 1.4em;
  font-weight: bold;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease;
}

.faq summary:hover {
  color: var(--color-primary);
  ;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

/* @keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 55px}
} */

.faq details[open] summary {
  color: var(--color-primary);
  ;
}

.faq details[open] p {
  border-left: 2px solid var(--color-primary);
  ;
  margin-left: 55px;
  padding-left: 25px;
  opacity: 100;
  transition: all 3s ease;
}

.faq details[open] summary:before {
  content: "-";
  font-size: 3.2em;
  margin: -33px 0.35em 0 0;
  font-weight: 200;
  height: 100px;
}

.faq .faq-body {
  /* width: 70em;
  margin: 4em auto;
  box-shadow: 0px 0px 16px #5b5b5b;
  border-radius: 0.2em;
  padding: 5em;
  background-color: #fff; */

}

.faq .faq-list {
  width: 55em;
  margin: 1em auto;
  padding: 2em 0;
}

.faq summary::-webkit-details-marker {
  display: none;
}

.faq summary:before {
  background: transparent;
  border-radius: 0.3em;
  content: "+";
  color: var(--color-primary);
  ;
  float: left;
  font-size: 1.8em;
  font-weight: bold;
  margin: -0.3em 0.65em 0 0;
  padding: 0;
  text-align: center;
  width: 25px;
  height: 68px;
}

@media(max-width:950px) {
  .faq .faq-list {
    width: unset;
  }

  .faq-body {
    /* padding: 0px 20px; */
    width: 95vw;
  }

  .faq div.seperator {
    margin-left: -2px;
    width: 6em;
  }

  .faq summary {
    font-size: 1.2em;
  }

  .faq {
    justify-content: center;
    margin-left: unset;
  }

}